import React from "react";
import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import useAuthentication from "hooks/useAuthentication";

function Login() {
  const { loginWithEmailAndPassword, resetPassword } = useAuthentication();
  const [loginError, setLoginError] = React.useState("");
  const [resetPasswordError, setResetPasswordError] = React.useState("");
  const emailInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          padding: "50px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 3,
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Login
        </Typography>

        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values) => {
            if (values.email && values.password) {
              try {
                setLoginError("");
                await loginWithEmailAndPassword(values.email, values.password);
              } catch (error: unknown) {
                if (error instanceof Error) {
                  setLoginError("Please check your email and password");
                } else {
                  setLoginError("An unexpected error occurred");
                }
              }
            }
          }}
          validationSchema={yup.object({
            email: yup
              .string()
              .email("Invalid Email")
              .required("Email Required"),
            password: yup
              .string()
              .min(6, "Password must be at least 6 characters")
              .required("Password Required"),
          })}
        >
          {({ getFieldProps, errors, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <FormControl variant="standard" sx={{ mb: 2 }}>
                  <TextField
                    inputRef={emailInputRef}
                    type="email"
                    variant="outlined"
                    label="Email"
                    {...getFieldProps("email")}
                    id="email"
                    error={
                      (touched.email && !!errors.email) || !!resetPasswordError
                    }
                    helperText={
                      (touched.email && errors.email) || resetPasswordError
                    }
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#999",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333",
                        },
                      },
                    }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ mt: 2 }}>
                  <TextField
                    type="password"
                    variant="outlined"
                    label="Password"
                    {...getFieldProps("password")}
                    id="password"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#999",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333",
                        },
                      },
                    }}
                  />
                </FormControl>

                {loginError && (
                  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                    {loginError}
                  </Typography>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      textTransform: "uppercase",
                      backgroundColor: "#4caf50",
                      "&:hover": {
                        backgroundColor: "#388e3c",
                      },
                    }}
                  >
                    Login
                  </Button>
                  <Link
                    href="#"
                    onClick={() => {
                      setResetPasswordError("");
                      const emailSchema = yup
                        .string()
                        .email("Invalid Email")
                        .required("Email Required");

                      try {
                        emailSchema.validateSync(values.email);
                        resetPassword(values.email);
                      } catch (error) {
                        if (error instanceof Error) {
                          setResetPasswordError(error.message);
                          emailInputRef.current?.focus();
                        }
                      }
                    }}
                    sx={{ alignSelf: "center" }}
                  >
                    Reset Password
                  </Link>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
        <Box sx={{ marginTop: 8, width: "100%" }}>
          Having Trouble? Reach out to us at{" "}
          <Link sx={{ my: 2 }} href={"mailto:support@electronicsalpha.com"}>
            support@electronicsalpha.com
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
