import React from "react";
import "./CheckEmail.css"; // Import the CSS file
import { Link } from "@mui/material";

const CheckEmail: React.FC = () => {
  return (
    <div className="check-email-container">
      <h1>Check Your Email</h1>
      <p>We've sent you a link to reset your password.</p>
      <p>If you don't see it, please check your spam folder.</p>
      <p>
        If you still can't find it, reach out to us at{" "}
        <Link sx={{ my: 2 }} href={"mailto:support@electronicsalpha.com"}>
          support@electronicsalpha.com
        </Link>
      </p>
    </div>
  );
};

export default CheckEmail;
