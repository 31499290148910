import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import TokenService from "services/token.service";
import { useAlert } from "./alerts/useAlert";

type AuthenticationContextValue = {
  loggedIn: Boolean;
  setLoggedIn: (loggedIn: Boolean) => void;
};

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    Boolean(TokenService.get())
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default function useAuthentication() {
  const { loggedIn, setLoggedIn } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const { alertInfo } = useAlert();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const {
        data: { jwt },
      } = await api.post("/sessions", { email, password });

      TokenService.set(jwt);
      setLoggedIn(true);
    },
    [setLoggedIn]
  );

  const loginWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const {
          data: { jwt },
        } = await api.post("/login", { email, password });
        if (jwt) {
          TokenService.set(jwt);
          setLoggedIn(true);
          window.location.href = "/";
        }
      } catch (error: unknown) {
        console.error("Error logging in:", error);
        throw error;
      }
    },
    [setLoggedIn]
  );

  const resetPassword = useCallback(async (email: string) => {
    try {
      await api.post("/reset_password", { email });
      window.location.href = "/check-email";
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  }, []);

  const setPassword = useCallback(
    (token: string, password: string) => {
      api
        .post("/set_password", { token, password })
        .then(({ data: { jwt } }) => {
          if (jwt) {
            TokenService.set(jwt);
            setLoggedIn(true);
            window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error("Error in setPassword:", error);
        });
    },
    [setLoggedIn]
  );

  return {
    login,
    loggedIn,
    loginWithEmailAndPassword,
    resetPassword,
    setPassword,
  };
}
