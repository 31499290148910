import { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";

import useAuthentication from "hooks/useAuthentication";

function ResetPassword() {
  const token = window.location.pathname.split("/reset-password/")[1];
  const { setPassword } = useAuthentication();
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          padding: "50px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 3,
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Set Password
        </Typography>

        <Formik
          initialValues={{ newPassword: "", confirmPassword: "" }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (values.newPassword && values.confirmPassword && token) {
                await setPassword(token, values.newPassword);
                setSubmitError(null);
              } else {
                console.log("Missing newPassword, confirmPassword, or token");
              }
            } catch (error) {
              console.error("Error setting password:", error);
              setSubmitError("Failed to set password. Please try again.");
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={yup.object({
            newPassword: yup
              .string()
              .min(6, "Password must be at least 6 characters")
              .required("New Password Required"),
            confirmPassword: yup
              .string()
              .oneOf([yup.ref("newPassword")], "Passwords must match")
              .required("Confirm Password Required"),
          })}
        >
          {({ getFieldProps, errors, handleSubmit, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <FormControl variant="standard" sx={{ mb: 2 }}>
                  <TextField
                    type="password"
                    variant="outlined"
                    label="New Password"
                    {...getFieldProps("newPassword")}
                    id="newPassword"
                    error={touched.newPassword && !!errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#999",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333",
                        },
                      },
                    }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ mt: 2 }}>
                  <TextField
                    type="password"
                    variant="outlined"
                    label="Confirm Password"
                    {...getFieldProps("confirmPassword")}
                    id="confirmPassword"
                    error={touched.confirmPassword && !!errors.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#999",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333",
                        },
                      },
                    }}
                  />
                </FormControl>

                {submitError && (
                  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                    {submitError}
                  </Typography>
                )}

                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      textTransform: "uppercase",
                      backgroundColor: "#4caf50",
                      "&:hover": {
                        backgroundColor: "#388e3c",
                      },
                    }}
                  >
                    Set Password
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
        <Box sx={{ marginTop: 8, width: "100%" }}>
          Having Trouble? Reach out to us at{" "}
          <Link sx={{ my: 2 }} href={"mailto:support@electronicsalpha.com"}>
            support@electronicsalpha.com
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
